import React from "react";
import ReactDOM from "react-dom";
import Logo from "./components/Logo";
import Contact from "./components/Contact";

import "./styles.css";

function App() {
    return (
        <div className="App">
            <div className="container">
                <Logo />
                <Contact />
            </div>
        </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
