import React, { Component } from "react";
import "./Contact.css";
import iconPhone from "./images/icon-phone.png";
import iconMail from "./images/icon-mail.png";

class Contact extends Component {
    render() {
        return (
            <div className="component">
                <div className="contact">
                    <div className="col-4">
                        <div className="box">
                            <img src={iconPhone} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="box">
                            <p><a href="tel:+491784347670">+49 178 434 76 70</a></p>
                        </div>
                    </div>
                </div>
                <div className="contact">
                    <div className="col-4">
                        <div className="box">
                            <img src={iconMail} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="box">
                            <p><a href="mailto:autogassysteme@gmail.com">autogassysteme@gmail.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;
